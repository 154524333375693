export const serialCodeImportExample = [
  { 兌換碼: '123456789' },
  { 兌換碼: 'HD94JFOV' },
]

export const couponTypeConfig = {
  coupon: { label: '使用券', value: 'coupon' },
  exchange: { label: '兌換券', value: 'exchange' },
  opentix: { label: 'opentix 優惠券', value: 'opentix' },
  pospal: { label: '瑞乘 優惠券', value: 'pospal' },
}
